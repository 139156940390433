// Components
import Layout from "../../components/Layout/Layout";
import ImageBlock from "../../components/ImageBlock/ImageBlock";
import SocialMediaAccounts from "../../components/SocialMediaAccountsSection/SocialMediaAccountsSection";

// Image
import Computer11 from "../../assets/images/Computer11.webp";

function Contact() {
  return (
    <Layout>
      <ImageBlock image={Computer11} showText={false} />
      <SocialMediaAccounts
        contactVersion="social-media-account-large-margin"
        pageVersion="contact-page-version"
      />
    </Layout>
  );
}

export default Contact;
