// Components
import Layout from "../../components/Layout/Layout";
import Presentation from "../../components/Presentation/Presentation";
import SocialMediaAccountsSection from "../../components/SocialMediaAccountsSection/SocialMediaAccountsSection";
import ProjectsList from "../../components/ProjectsList/ProjectsList";
import ImageBlock from "../../components/ImageBlock/ImageBlock";
import ImageAndTextBlock from "../../components/ImageAndTextBlock/ImageAndTextBlock";
import ServicesList from "../../components/ServicesList/ServicesList";
import ContactSection from "../../components/ContactSection/ContactSection";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Reviews from "../../components/Reviews/Reviews";

// Style
import "../../assets/styles/Global.scss";
import "../Home/Home.scss";
import "./../../assets/styles/Fonts.scss";

// Images
import Computer1 from "../../assets/images/Computer.webp";
import Me1 from "../../assets/images/Me1.jpeg";
import Me5 from "../../assets/images/Me5.jpeg";

function Home() {
  return (
    <Layout>
      <ImageBlock image={Computer1} />

      <Presentation />

      <ImageAndTextBlock
        text="Ma démarche repose sur l'écoute et la collaboration, afin de créer des solutions web sur mesure. Je privilégie l'engagement et la qualité pour concevoir des sites durables, évolutifs et adaptés à vos besoins."
        image={Me5}
        sectionTitle="MON APPROCHE."
        subtitle="Collaboration, engagement & qualité"
      />

      <div className="home-page__title-section">
        <SectionTitle
          title="SERVICES PROPOSÉS."
          className=""
          margin="large-margin"
          mobileVersion="version-with-text-align"
        />
      </div>

      <ServicesList />

      <SocialMediaAccountsSection
        backgroundVersion="version-with-background-color"
        pageVersion="home-page-version"
      />

      <ImageAndTextBlock
        text={`Après 7 ans d'expérience en communication, je me suis lancée dans le développement web, où j'allie organisation et professionnalisme pour créer des solutions à la fois élégantes et performantes.
        <br></br> Passionnée par la littérature depuis mon plus jeune âge, j'aime concevoir des solutions web qui allient narration et performance, tout en répondant aux attentes techniques et esthétiques.`}
        image={Me1}
        className="row-reverse-version"
        sectionTitle="MON HISTOIRE."
      />

      <Reviews />

      <ProjectsList />

      <ContactSection />
    </Layout>
  );
}

export default Home;
