// React element
import { useLocation } from "react-router-dom";

// Components
import SectionTitle from "../SectionTitle/SectionTitle";
import SecondTitleForSection from "../SecondTitleForSection/SecondTitleForSection";

// Style
import "./ImageAndTextBlock.scss";

function ImageAndTextBlock({ sectionTitle, text, image, className, subtitle }) {
  const location = useLocation();

  return (
    <div className={`image-and-text-block ${className}`}>
      <div className="image-and-text-block__text">
        {location.pathname === "/" ? (
          <SectionTitle
            title={sectionTitle}
            className=""
            margin="large-margin"
          />
        ) : (
          <SecondTitleForSection title={sectionTitle} />
        )}
        <h3 className="image-and-text-block__text__subtitle">{subtitle}</h3>
        <p
          className="image-and-text-block__paragraph"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>

      <div className="image-and-text-block__image-container">
        <img
          src={image}
          alt=""
          className="image-and-text-block__image-container__image image-with-opacity image-with-border-radius"
        ></img>
      </div>
    </div>
  );
}

export default ImageAndTextBlock;
