// React elements
import { useState, useEffect, useCallback } from "react";

// Context
import { useLanguage } from "../../context/LanguageContext";

// Components
import Project from "../Project/Project";
import SectionTitle from "../SectionTitle/SectionTitle";
import Button from "../Button/Button";

// Data
import projectsList from "../../assets/data/projectsList.json";

// Style
import "./ProjectsList.scss";

function ProjectsList() {
  const [visibleProjects, setVisibleProjects] = useState([]);

  const { language } = useLanguage();

  return (
    <section id="projects" className="projects">
      <div className="projects__title-container">
        <SectionTitle
          title={language === "FR" ? "MES PROJETS." : "MY PROJECTS."}
          margin="large-margin"
          className=""
          mobileVersion="version-with-text-align"
        />
      </div>
      <div className="projects__container">
        {projectsList.slice(0, 3).map((project, projectIndex) => (
          <Project
            key={projectIndex}
            title={project.title}
            description={project.description}
            details={project.details}
            technologies={project.technologies}
            source={project.source}
            image={project.image}
            projectIndex={projectIndex}
            isVisible={visibleProjects.includes(projectIndex)}
            position={
              projectIndex % 2 === 0 ? "project--right" : "project--left"
            }
          />
        ))}
        <Button text="DÉCOUVRIR PLUS DE PROJETS" link="/projects" />
      </div>
    </section>
  );
}

export default ProjectsList;
