// Style
import "./SectionTitle.scss";

function SectionTitle({ title, className, margin, mobileVersion }) {
  return (
    <h2 className={`section-title ${margin} ${mobileVersion}`}>{title}</h2>
  );
}

export default SectionTitle;
