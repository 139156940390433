// React elements
import { useLocation } from "react-router-dom";

// Context
import { useLanguage } from "../../context/LanguageContext";

// Components
import SocialMedia from "../SocialMedia/SocialMedia";
import SectionTitle from "../SectionTitle/SectionTitle";

// Style
import "./SocialMediaAccountsSection.scss";

function SocialMediaAccounts({ contactVersion, pageVersion }) {
  const { language } = useLanguage();

  const location = useLocation();

  return (
    <section className={`social-media-accounts  ${pageVersion}`}>
      <SectionTitle
        title="FAISONS CONNAISSANCE."
        className=""
        margin="large-margin"
        // margin={location.pathname === "/" ? "small-margin" : "large-margin"}
        mobileVersion="contact-section-mobile-version "
      />

      <p className="social-media-accounts__question">
        Vous avez des idées ou questions ?
      </p>

      <p className={`social-media-accounts__answer ${contactVersion}`}>
        Je serais ravie de discuter avec vous.
      </p>

      <SocialMedia version="light" />
    </section>
  );
}

export default SocialMediaAccounts;
