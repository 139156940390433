// Components
import Layout from "../../components/Layout/Layout";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import ImageBlock from "../../components/ImageBlock/ImageBlock";
import ContactSection from "../../components/ContactSection/ContactSection";
import Experiences from "../../components/Experiences/Experiences";
import Introduction from "../../components/Introduction/Introduction";
import SecondTitleForSection from "../../components/SecondTitleForSection/SecondTitleForSection";
import ImageAndTextBlock from "../../components/ImageAndTextBlock/ImageAndTextBlock";

// Style
import "./About.scss";

// Images
import Computer10 from "../../assets/images/Computer10.webp";
import Me3 from "../../assets/images/Me3.jpeg";
import Me4 from "../../assets/images/Me4.jpeg";

function About() {
  return (
    <Layout>
      <ImageBlock image={Computer10} showText={false} />

      <div className="about-page__title">
        <SectionTitle
          title="MON HISTOIRE."
          margin="large-margin"
          mobileVersion="version-with-text-align"
        />
      </div>

      <div className="about__introduction">
        <Introduction
          text="Je suis Amale, passionnée par les mots, la rédaction et le développement web. Après 7 ans d'expérience en communication, j'ai choisi d'allier cette expertise avec le développement web. Cela me permet de mêler rigueur technique et sens de la créativité, tout en apportant une réelle valeur ajoutée en élaborant des solutions digitales performantes, esthétiques et adaptées aux attentes de chacun."
          versionWithMargin="version-with-margin"
        />
      </div>

      <ImageAndTextBlock
        text={`Mon parcours en communication m'a appris à comprendre les besoins
        des clients, à structurer des projets et à travailler avec des
        équipes multidisciplinaires. Au fil des années, j’ai acquis une
        expertise solide en gestion de projets tout en cultivant un esprit
        créatif. <br> </br> C’est cette capacité à allier organisation et créativité
        qui m’a poussée à me tourner vers le développement web. J'y ai vu un
        moyen d’avoir un impact direct, en créant des interfaces à la fois
        fonctionnelles et esthétiques, pour une expérience utilisateur
        optimale.`}
        image={Me3}
        sectionTitle="Mon parcours"
      />

      <Experiences />

      <div className="about__development-web-section">
        <SecondTitleForSection title="Pourquoi le développement web ?" />
        <div className="horizontal-line__about-version"></div>
        <p>
          Le développement web n’est pas qu’une technique pour moi, c’est une
          nouvelle façon de penser et de résoudre des problèmes. Ce qui me
          passionne, c'est la possibilité de donner vie à des idées, de les
          transformer en interfaces utiles et engageantes.
          <br />
          <br />
          J’aime voir un projet se concrétiser, de la conception à la mise en
          ligne, et savoir qu'il apportera une véritable valeur ajoutée à ceux
          qui l’utilisent.
        </p>
      </div>

      <ImageAndTextBlock
        text={`Depuis toujours, les mots et la littérature occupent une place
        importante dans ma vie. Cette passion se reflète aussi dans mon
        approche du web : je crois que chaque site, chaque application
        raconte une histoire, et j'aime être l'artisane qui crée cette
        narration digitale. <br></br> Lorsque je ne suis pas devant un
        écran à coder, il n’est pas rare que je sois en train de lire ou
        d’écrire, toujours à la recherche de nouvelles façons d'allier
        créativité et performance.`}
        image={Me4}
        sectionTitle="Mes passions"
      />

      <ContactSection />
    </Layout>
  );
}

export default About;
