// Components
import Layout from "../../components/Layout/Layout";
import ImageBlock from "../../components/ImageBlock/ImageBlock";
import SkillsList from "../../components/SkillsList/SkillsList";
import ContactSection from "../../components/ContactSection/ContactSection";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Introduction from "../../components/Introduction/Introduction";

// Style
import "./Skills.scss";

// Images
import Computer3 from "../../assets/images/Computer3.webp";
import Computer7 from "../../assets/images/Computer7.webp";

function Skills() {
  return (
    <Layout>
      <ImageBlock image={Computer3} showText={false} />

      <div className="skills-page-container">
        <SectionTitle
          title="MES COMPÉTENCES."
          margin="large-margin"
          mobileVersion="version-with-text-align"
        />
        <Introduction
          text="Au fil de mes projets, j’ai développé des compétences techniques diversifiées, tant en front-end qu'en back-end, afin de concevoir des solutions web performantes."
          versionWithMargin="version-with-margin"
        />
        <SkillsList />
      </div>
      {/* <ImageBlock image={Computer7} showText={false} /> */}
      <ContactSection />
    </Layout>
  );
}

export default Skills;
