// React elements
import { useState, useEffect } from "react";

// Context
import { useLanguage } from "../../context/LanguageContext";

// Component
import Technologies from "../Technologies/Technologies";

// Style
import "./SkillsList.scss";

function SkillsList() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

  const { language } = useLanguage();

  const handleResize = () => {
    setIsMobile(window.innerWidth < 576);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section id="skills" className="skills">
      <Technologies mobileVersion={"mobile-version"} />
    </section>
  );
}

export default SkillsList;
