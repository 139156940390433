// Component
import ExperienceItem from "../ExperienceItem/ExperienceItem";
import VerticalLine from "../VerticalLine/VerticalLine";
import SecondTitleForSection from "../SecondTitleForSection/SecondTitleForSection";

// Data
import experiencesList from "../../assets/data/experiencesList.json";

// Style
import "./Experiences.scss";

// Image
import Working from "../../assets/images/Working.webp";

function Experiences() {
  return (
    <div className="experiences-list">
      <div className="experiences-list__image-container">
        <img
          src={Working}
          className="experiences-list__image-container__image image-with-opacity image-with-border-radius"
        />
      </div>
      {/* <VerticalLine /> */}
      <div className="experiences-list__texts">
        <SecondTitleForSection title="Mes expériences" />
        <div className="experiences-list__block">
          {experiencesList.map((experienceItem, experienceItemIndex) => (
            <ExperienceItem
              key={experienceItemIndex}
              year={experienceItem.year}
              description={experienceItem.description}
              experience={experienceItem.experience}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Experiences;
