// Components
import Layout from "../../components/Layout/Layout";
import Project from "../../components/Project/Project";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import ImageBlock from "../../components/ImageBlock/ImageBlock";
import ContactSection from "../../components/ContactSection/ContactSection";
import Introduction from "../../components/Introduction/Introduction";

// Data
import projectsList from "../../assets/data/projectsList.json";

// Style
import "./Projects.scss";

// Images
import Computer2 from "../../assets/images/Computer2.webp";
import Computer7 from "../../assets/images/Computer7.webp";

function Projects() {
  return (
    <Layout>
      <ImageBlock image={Computer2} showText={false} />
      <div className="projects-page-container">
        <SectionTitle
          title="MES PROJETS."
          margin="large-margin"
          mobileVersion="version-with-text-align"
        />
        <Introduction
          text="Chaque projet m'a permis d'apprendre et d'affiner mes compétences en développement web, avec pour objectif de toujours proposer des solutions performantes et adaptées."
          versionWithMargin="version-with-margin"
        />
        <div className="projects__container page-projects">
          {projectsList.map((project, projectIndex) => (
            <Project
              key={projectIndex}
              title={project.title}
              description={project.description}
              details={project.details}
              technologies={project.technologies}
              source={project.source}
              image={project.image}
              projectIndex={projectIndex}
              position={
                projectIndex % 2 === 0 ? "project--right" : "project--left"
              }
            />
          ))}
        </div>
      </div>
      <ContactSection />
    </Layout>
  );
}

export default Projects;
