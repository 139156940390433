// React element
import { useEffect, useState } from "react";

// Context
import { useLanguage } from "../../context/LanguageContext";

// Components
import SocialMedia from "../SocialMedia/SocialMedia";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import Name from "../Name/Name";

// Font Awesome icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

// Style
import "./Header.scss";

function Header() {
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("FR");

  const { language } = useLanguage();

  const toggleHamburgerMenu = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
    // if (!isHamburgerMenuOpen) {
    //   document.body.style.overflow = "hidden";
    // } else {
    //   document.body.style.overflow = "auto";
    // }
  };

  useEffect(() => {
    if (isHamburgerMenuOpen) {
      document.body.style.overflow = "hidden"; // Désactiver le scroll
    } else {
      document.body.style.overflow = "auto"; // Réactiver le scroll
    }

    return () => {
      document.body.style.overflow = "auto"; // Réinitialiser à la fermeture du menu
    };
  }, [isHamburgerMenuOpen]);

  return (
    <header
      className={`header  ${
        isHamburgerMenuOpen ? "header-mobile-version" : ""
      }`}
    >
      {!isHamburgerMenuOpen && (
        <>
          <Name version="black" />

          <div className="header__links-block">
            <nav className="header__links__nav">
              <ul>
                <li>
                  <a href="/projects">
                    {language === "FR" ? "PROJETS" : "PROJECTS"}
                  </a>
                </li>
                <li>
                  <a href="/services">
                    {language === "FR" ? "SERVICES" : "SERVICES"}
                  </a>
                </li>
                <li>
                  <a href="/about">
                    {language === "FR" ? "HISTOIRE" : "ABOUT"}
                  </a>
                </li>
                <li>
                  <a href="/skills">
                    {language === "FR" ? "COMPÉTENCES" : "SKILLS"}
                  </a>
                </li>
              </ul>
            </nav>

            <SocialMedia version="dark" />

            {/* <LanguageSelector
              currentLanguage={currentLanguage}
              setCurrentLanguage={setCurrentLanguage}
            /> */}

            <div className="menu-hamburger">
              <button
                className={`menu-hamburger__button ${
                  isHamburgerMenuOpen ? "open" : ""
                }`}
                onClick={toggleHamburgerMenu}
                aria-expanded={isHamburgerMenuOpen}
                aria-label="Toggle navigation menu"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </>
      )}

      <div
        className={`menu-hamburger__nav ${isHamburgerMenuOpen ? "open" : ""}`}
      >
        <FontAwesomeIcon
          icon={faXmark}
          onClick={() => setIsHamburgerMenuOpen(false)}
        />

        <div className="menu-hamburger__nav__block ">
          <nav>
            <ul>
              <li
                onClick={() => {
                  setIsHamburgerMenuOpen(false);
                }}
              >
                <a href="/projects">
                  {language === "FR" ? "PROJETS" : "PROJECTS"}
                </a>
              </li>
              <li
                onClick={() => {
                  setIsHamburgerMenuOpen(false);
                }}
              >
                <a href="/services">
                  {language === "FR" ? "SERVICES" : "SERVICES"}
                </a>
              </li>
              <li
                onClick={() => {
                  setIsHamburgerMenuOpen(false);
                }}
              >
                <a href="/about">{language === "FR" ? "HISTOIRE" : "ABOUT"}</a>
              </li>
              <li
                onClick={() => {
                  setIsHamburgerMenuOpen(false);
                }}
              >
                <a href="/skills">
                  {language === "FR" ? "COMPÉTENCES" : "SKILLS"}
                </a>
              </li>
            </ul>
          </nav>

          <div className="menu-hamburger__nav__links">
            {/* <LanguageSelector
              currentLanguage={currentLanguage}
              setCurrentLanguage={setCurrentLanguage}
            /> */}
            <SocialMedia version="dark" />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
