// React elements
import { useState, useEffect } from "react";

// Components
import Button from "../../components/Button/Button";
import SectionTitle from "../../components/SectionTitle/SectionTitle";

// Style
import "./ContactSection.scss";

function ContactSection() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 576);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const mobileTitle = "Vous avez un projet \n en tête ?";
  const desktopTitle = "Vous avez un projet en tête ?";

  return (
    <section className="contact-section">
      <SectionTitle
        title="Vous avez un projet en tête ?"
        margin="small-margin contact-section-mobile-version"
      />
      <div className="horizontal-line__services-version"></div>

      <p className="contact-section__answer">
        Je suis là pour écouter vos idées et trouver ensemble les meilleures
        solutions.
      </p>
      <Button text="PRENONS CONTACT" link="/contact" />
    </section>
  );
}

export default ContactSection;
