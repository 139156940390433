// Context
import { useLanguage } from "../../context/LanguageContext";

// Style
import "./Presentation.scss";

// Image
// import Photo from "../../assets/images/amale-dkhissi.webp";
import Me2 from "../../assets/images/Me2.jpeg";

function Presentation() {
  const { language } = useLanguage();

  return (
    <section className="presentation-container">
      <div className="presentation-container__photo-container">
        <img
          src={Me2}
          alt="Portrait professionnel"
          className="presentation-container__photo-container__photo  image-with-opacity"
        ></img>
      </div>
      <div className="presentation-container__text">
        {/* <h1>
          Hello ! <br></br> {language === "FR" ? "Moi, c'est " : "I'm "}
          Amale, <br></br>
          {language === "FR" ? <>Développeuse web.</> : <>Web Developer.</>}
        </h1> */}
        <h1>
          Amale, <br></br> Créatrice de sites web <br></br>& Rédactrice de
          contenus
        </h1>
        <div className="presentation-container__text__presentation">
          {language === "FR"
            ? "Je conçois des solutions web sur mesure et des contenus engageants, qui vous ressemblent et optimisent votre visibilité en ligne. Chaque projet est une opportunité d'explorer de nouvelles idées."
            : "My enthusiasm for web development has driven me to explore this exciting field. Guided by a genuine interest in innovation, I aspire to create solutions that turn ideas into reality and enhance user experiences. For me, each project is an opportunity to learn and grow in this ever-evolving domain."}
        </div>
      </div>
    </section>
  );
}

export default Presentation;
