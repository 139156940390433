// Components
import Layout from "./../../components/Layout/Layout";
import ImageBlock from "../../components/ImageBlock/ImageBlock";
import ServicesList from "./../../components/ServicesList/ServicesList";
import ContactSection from "../../components/ContactSection/ContactSection";
import Introduction from "../../components/Introduction/Introduction";

// Style
import "./Services.scss";

// Images
import Computer4 from "../../assets/images/Computer4.webp";
import Computer7 from "../../assets/images/Computer7.webp";
import Computer8 from "../../assets/images/Computer8.webp";
import SectionTitle from "../../components/SectionTitle/SectionTitle";

function Services() {
  return (
    <Layout>
      <ImageBlock image={Computer8} showText={false} />

      <div className="services-page">
        <div className="services-page__title">
          <SectionTitle
            title="SERVICES PROPOSÉS."
            margin="large-margin"
            mobileVersion="version-with-text-align"
          />
        </div>

        <div className="services-page__introduction">
          <Introduction
            text="Mon objectif est de vous accompagner dans vos projets digitaux en proposant des solutions sur mesure alliant performance, visibilité et impact, qu'il s'agisse de sites web, d'optimisation de votre référencement ou de rédaction de contenus."
            versionWithMargin="version-with-margin"
          />
        </div>

        <ServicesList />
      </div>
      <ContactSection />
    </Layout>
  );
}

export default Services;
